html {
    -moz-font-feature-settings: 'kern', 'mgrk';
    -moz-font-feature-settings: 'kern=1', 'mgrk=1';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-feature-settings: 'kern', 'mgrk';
    -webkit-font-smoothing: antialiased;
    font-feature-settings: 'kern', 'mgrk';
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    width: 100%;
}

body {
    overflow: hidden;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
}

::-moz-selection {
    background-color: #1a3869;
    color: #fff;
}

::selection {
    background-color: #1a3869;
    color: #fff;
}

.skip-to-main-link {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: black;
  color: white;
  opacity: 0;
}
